<template>
  <b-overlay :show="loading">
    <ag-grid-vue
      :gridOptions="gridOptions"
      class="ag-theme-balham ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      @grid-ready="onGridReady"
      :rowData="items"
      rowSelection="single"
      colResizeDefault="shift"
      :animateRows="false"
      :floatingFilter="false"
      :pagination="false"
      :rowClassRules="rowClassRules"
      @selection-changed="onSelectionChanged"
      @first-data-rendered="onFirstDataRendered"
      :alwaysShowVerticalScroll="true"
      :suppressPaginationPanel="true"
    >
    </ag-grid-vue>
  </b-overlay>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { BCard, BCardText, BOverlay } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardText,
    AgGridVue,
    BOverlay,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      gridOptions: {
        suppressPropertyNamesCheck: true,
        localeText: {
          noRowsToShow: "No hay filas para mostrar",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,
      gridApi: null,
      selectedRows: [],
      defaultColDef: {
        sortable: false,
        filter: true,
        floatingFilter: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "CODIGO",
          field: "codigo",
          filter: false,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "NOMBRE",
          field: "nombre",
          filter: false,
          minWidth: 300,
        },
        {
          headerName: "ACEPTA REGISTRO",
          cellRenderer: function (params) {
            if (
              ["Contable Recursos", "Contable Gastos"].includes(
                params.data.tipo_partida
              )
            ) {
              return "-";
            }
            return params.data.acepta_registro ? "SI" : "NO";
          },
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "CUENTA CONTABLE",
          cellRenderer: function (params) {
            if (!params.data.cuenta_contable) {
              if (
                ["Contable Recursos", "Contable Gastos"].includes(
                  params.data.tipo_partida
                )
              ) {
                return "-";
              }

              return "";
            }
            return `${params.data.cuenta_contable?.codigo} ${params.data.cuenta_contable?.nombre}`;
          },
          filter: false,
          minWidth: 300,
          maxWidth: 300,
        },
        {
          headerName: "PARTIDA PADRE",
          field: "padre_id",
          cellRenderer: function (params) {
            if (
              ["Contable Recursos", "Contable Gastos"].includes(
                params.data.tipo_partida
              )
            ) {
              return "-";
            }
            if (!params.data.padre) {
              return "";
            }
            return `<small>${params.data.padre?.codigo} ${params.data.padre?.nombre}</small>`;
          },
          filter: false,
          minWidth: 300,
          maxWidth: 300,
        },
        {
          headerName: "DESCRIPCION",
          field: "descripcion",
          filter: false,
          minWidth: 300,
          maxWidth: 300,
        },
      ],
      searchQuery: "",
    };
  },
  computed: {},
  created() {},
  methods: {
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onSelectionChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      this.selectedRows = selectedRows;
      if (this.selectedRows != "") {
        this.$emit("selectItemClasificador", this.selectedRows[0], false);
      } else {
        this.selectedRows = [];
        this.$emit("selectItemClasificador", this.selectedRows, true);
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
.ag-theme-balham .row-red {
  color: #ea5455 !important;
}
</style>
