<template>
  <b-modal
    id="modal-from-clasificador"
    v-model="modalClasificador"
    ref="modal"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    no-close-on-esc
    ok-title="Guardar"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    button-size="sm"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    />
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col class="mb-0-5" cols="12">
            <b-row class="mb-1">
              <b-col class="mb-0-5" cols="12" xl="3">
                <b-form-group label="* CODIGO " label-for="codigo">
                  <validation-provider
                    #default="{ errors }"
                    name="Codigo"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="codigo"
                      v-model="form.codigo"
                      lazy-formatter
                      :formatter="textFormatter"
                      :state="errors.length > 0 ? false : null"
                      size="sm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-for="error in errores.codigo"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col class="mb-0-5" cols="12" xl="9">
                <b-form-group label="* NOMBRE " label-for="nombre">
                  <validation-provider
                    #default="{ errors }"
                    name="nombre"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="nombre"
                      v-model="form.nombre"
                      lazy-formatter
                      :formatter="textFormatter"
                      :state="errors.length > 0 ? false : null"
                      size="sm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-for="error in errores.nombre"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col class="mb-0-5" cols="12">
                <b-form-group label="DESCRIPCION " label-for="descripcion">
                  <validation-provider #default="{ errors }" name="descripcion">
                    <b-form-input
                      id="descripcion"
                      v-model="form.descripcion"
                      lazy-formatter
                      :formatter="textFormatter"
                      :state="errors.length > 0 ? false : null"
                      size="sm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-for="error in errores.descripcion"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                class="mb-0-5"
                cols="12"
                v-if="seMuestranCamposPresupuestarios"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Clasificador padre"
                >
                  <b-form-group
                    label="CLASIFICADOR PADRE"
                    label-for="clasificadorPadre"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="clasificadorPadre"
                      v-model="form.padre_id"
                      class="style-chooser select-size-sm"
                      label="nombre"
                      :options="partidasItems"
                      :key="form.padre_id"
                      :reduce="(op) => op.id"
                      :class="{ 'is-invalid': !!errors.length }"
                    >
                      <template v-slot:option="option">
                        <span v-html="option.descripcion_tabulada"></span>
                      </template>
                    </v-select>
                    <small
                      v-for="error in errores.padre_id"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                class="mb-0-5"
                cols="12"
                v-if="seMuestranCamposPresupuestarios"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cuenta contable"
                >
                  <b-form-group
                    label="CUENTA CONTABLE"
                    label-for="cuentaContable"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="cuentaContable"
                      v-model="form.cuenta_contable_id"
                      class="style-chooser select-size-sm"
                      label="nombre"
                      :options="clasificadoresContables"
                      :key="form.cuenta_contable_id"
                      :reduce="(op) => op.id"
                      :class="{ 'is-invalid': !!errors.length }"
                    />
                    <small
                      v-for="error in errores.cuenta_contable_id"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row v-if="seMuestranCamposPresupuestarios">
              <b-col class="mb-0-5" cols="12">
                <b-form-group>
                  <b-form-checkbox v-model="form.acepta_registro" :value="true">
                    ACEPTA REGISTRO
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import ClasificadorServices from "../services/index";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormSelect,
  BImg,
  BFormFile,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import vSelect from "vue-select";

export default {
  name: "ModalFormClasificador",
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
    vSelect,
    BImg,
    BFormFile,
    BFormTextarea,
    BFormCheckbox,
  },
  props: {
    tipoPartida: {
      type: String,
      required: true,
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      options: {},
      form: {
        id: null,
        codigo: "",
        descripcion: "",
        nombre: "",
        tipo_partida: this.tipoPartida,
        padre_id: null,
        cuenta_contable_id: null,
        acepta_registro: false,
      },
      partidasItems: [],
      clasificadoresContables: [],

      required,
      submitted: false,
      errores: [],
      modalClasificador: false,
      estadoItems: [
        { text: "ACTIVO", value: 1 },
        { text: "INACTIVO", value: 0 },
      ],
    };
  },
  computed: {
    tituloModal() {
      return this.form.id ? "EDITAR PARTIDA" : "NUEVA PARTIDA";
    },
    seMuestranCamposPresupuestarios() {
      return this.tipoPartida == "Gastos" || this.tipoPartida == "Ingresos";
    },
  },
  watch: {
    "form.codigo": function () {
      this.errores.codigo = [];
    },
    "form.nombre": function () {
      this.errores.nombre = [];
    },
    "form.descripcion": function () {
      this.errores.descripcion = [];
    },
    "form.padre_id": function () {
      this.errores.padre_id = [];
    },
    "form.cuenta_contable_id": function () {
      this.errores.cuenta_contable_id = [];
    },
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    abrir(accion, data = []) {
      this.obtenerClasificadoresContables();
      this.getPartidas();
      switch (accion) {
        case "registrar": {
          this.form.id = null;
          this.form.codigo = "";
          this.form.descripcion = "";
          this.form.nombre = "";
          this.form.tipo_partida = this.tipoPartida;
          this.form.padre_id = null;
          this.form.cuenta_contable_id = null;
          this.form.acepta_registro = false;
          break;
        }
        case "actualizar": {
          this.form.id = data["id"];
          this.form.codigo = data["codigo"];
          this.form.descripcion = data["descripcion"];
          this.form.nombre = data["nombre"];
          this.form.tipo_partida = data["tipo_partida"];
          this.form.padre_id = data["padre_id"];
          this.form.cuenta_contable_id = data["cuenta_contable_id"];
          this.form.acepta_registro = data["acepta_registro"];
          break;
        }
      }
      this.modalClasificador = true;
    },

    cerrarModal() {
      this.form.id = null;
      this.modalClasificador = false;
    },
    actualizarPartida() {
      this.form.id = null;
      this.modalClasificador = false;
      this.$emit("actualizarListado");
      this.submitted = false;
    },
    // SERVICES
    async registrar() {
      try {
        await ClasificadorServices.store(this.form);
        this.actualizarPartida();
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      try {
        await ClasificadorServices.update(this.form.id, this.form);
        this.actualizarPartida();
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
    async getPartidas() {
      try {
        const response = await ClasificadorServices.getTipoPartida(
          this.tipoPartida
        );
        const partidas = response.data;
        const partidasAplanadas = [];
        this.formatearPartidas(partidas, partidasAplanadas, "", 0);

        this.partidasItems = partidasAplanadas;
      } catch (err) {
        console.log(err);
      }
    },

    formatearPartidas(partidas, partidasAplanadas, nivel, nivelaux) {
      nivel += "&nbsp;&nbsp;&nbsp;";
      nivelaux += 1;

      partidas.forEach((partida) => {
        partidasAplanadas.push({
          ...partida,
          descripcion_tabulada: `${nivel}${partida.codigo} ${partida.nombre}`,
          disabled: !partida.acepta_registro,
          nivel: nivelaux,
          nombre: `${partida.codigo} ${partida.nombre}`,
          codigo: partida.codigo,
          saldo: partida?.saldo ? partida.saldo : 0,
        });

        this.formatearPartidas(
          partida.hijos,
          partidasAplanadas,
          nivel,
          nivelaux
        );
      });
    },

    async obtenerClasificadoresContables() {
      try {
        const response = await ClasificadorServices.get(
          `Contable ${this.tipoPartida}`
        );
        this.clasificadoresContables = response.data.map(
          (clasificadorContable) => ({
            id: clasificadorContable.id,
            nombre: `${clasificadorContable.codigo} ${clasificadorContable.nombre}`,
          })
        );
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.options = {
      modules: {
        toolbar: [
          ["bold", "italic"],
          [{ size: ["small", false, "large", "huge"] }],
          [{ font: [] }],
        ],
      },
      theme: "snow",
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>