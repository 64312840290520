var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"id":"modal-from-clasificador","title":_vm.tituloModal,"no-close-on-backdrop":"","ok-disabled":_vm.submitted,"cancel-disabled":_vm.submitted,"no-close-on-esc":"","ok-title":"Guardar","ok-variant":"primary","cancel-variant":"outline-secondary","cancel-title":"Cerrar","button-size":"sm"},on:{"cancel":function($event){$event.preventDefault();return _vm.cerrarModal.apply(null, arguments)},"close":function($event){$event.preventDefault();return _vm.cerrarModal.apply(null, arguments)},"ok":function($event){$event.preventDefault();return _vm.validar.apply(null, arguments)}},model:{value:(_vm.modalClasificador),callback:function ($$v) {_vm.modalClasificador=$$v},expression:"modalClasificador"}},[_c('b-overlay',{attrs:{"show":_vm.submitted,"no-wrap":"","spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}}),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mb-0-5",attrs:{"cols":"12"}},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"mb-0-5",attrs:{"cols":"12","xl":"3"}},[_c('b-form-group',{attrs:{"label":"* CODIGO ","label-for":"codigo"}},[_c('validation-provider',{attrs:{"name":"Codigo","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"codigo","lazy-formatter":"","formatter":_vm.textFormatter,"state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.form.codigo),callback:function ($$v) {_vm.$set(_vm.form, "codigo", $$v)},expression:"form.codigo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.codigo),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{staticClass:"mb-0-5",attrs:{"cols":"12","xl":"9"}},[_c('b-form-group',{attrs:{"label":"* NOMBRE ","label-for":"nombre"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nombre","lazy-formatter":"","formatter":_vm.textFormatter,"state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.form.nombre),callback:function ($$v) {_vm.$set(_vm.form, "nombre", $$v)},expression:"form.nombre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.nombre),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),_c('b-col',{staticClass:"mb-0-5",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"DESCRIPCION ","label-for":"descripcion"}},[_c('validation-provider',{attrs:{"name":"descripcion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"descripcion","lazy-formatter":"","formatter":_vm.textFormatter,"state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.form.descripcion),callback:function ($$v) {_vm.$set(_vm.form, "descripcion", $$v)},expression:"form.descripcion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_vm._l((_vm.errores.descripcion),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})]}}])})],1)],1),(_vm.seMuestranCamposPresupuestarios)?_c('b-col',{staticClass:"mb-0-5",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Clasificador padre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CLASIFICADOR PADRE","label-for":"clasificadorPadre","state":errors.length > 0 ? false : null}},[_c('v-select',{key:_vm.form.padre_id,staticClass:"style-chooser select-size-sm",class:{ 'is-invalid': !!errors.length },attrs:{"id":"clasificadorPadre","label":"nombre","options":_vm.partidasItems,"reduce":function (op) { return op.id; }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',{domProps:{"innerHTML":_vm._s(option.descripcion_tabulada)}})]}}],null,true),model:{value:(_vm.form.padre_id),callback:function ($$v) {_vm.$set(_vm.form, "padre_id", $$v)},expression:"form.padre_id"}}),_vm._l((_vm.errores.padre_id),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})],2)]}}],null,false,3859690863)})],1):_vm._e(),(_vm.seMuestranCamposPresupuestarios)?_c('b-col',{staticClass:"mb-0-5",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Cuenta contable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CUENTA CONTABLE","label-for":"cuentaContable","state":errors.length > 0 ? false : null}},[_c('v-select',{key:_vm.form.cuenta_contable_id,staticClass:"style-chooser select-size-sm",class:{ 'is-invalid': !!errors.length },attrs:{"id":"cuentaContable","label":"nombre","options":_vm.clasificadoresContables,"reduce":function (op) { return op.id; }},model:{value:(_vm.form.cuenta_contable_id),callback:function ($$v) {_vm.$set(_vm.form, "cuenta_contable_id", $$v)},expression:"form.cuenta_contable_id"}}),_vm._l((_vm.errores.cuenta_contable_id),function(error){return _c('small',{key:error,staticClass:"text-danger"},[_vm._v(_vm._s(error))])})],2)]}}],null,false,251753958)})],1):_vm._e()],1),(_vm.seMuestranCamposPresupuestarios)?_c('b-row',[_c('b-col',{staticClass:"mb-0-5",attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"value":true},model:{value:(_vm.form.acepta_registro),callback:function ($$v) {_vm.$set(_vm.form, "acepta_registro", $$v)},expression:"form.acepta_registro"}},[_vm._v(" ACEPTA REGISTRO ")])],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }